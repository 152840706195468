// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-have-some-fun-tsx": () => import("./../../../src/pages/have-some-fun.tsx" /* webpackChunkName: "component---src-pages-have-some-fun-tsx" */),
  "component---src-pages-how-to-sell-tsx": () => import("./../../../src/pages/how-to-sell.tsx" /* webpackChunkName: "component---src-pages-how-to-sell-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-passphrase-tsx": () => import("./../../../src/pages/passphrase.tsx" /* webpackChunkName: "component---src-pages-passphrase-tsx" */),
  "component---src-pages-spanish-tsx": () => import("./../../../src/pages/spanish.tsx" /* webpackChunkName: "component---src-pages-spanish-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */)
}

